import { Component, OnDestroy, HostBinding, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  animations: fuseAnimations
})
export class ListViewComponent implements OnDestroy {
  @HostBinding('id')
  id = 'list-view';
  @Input() title: string;
  @Input() icon: string;

  constructor() {}

  ngOnDestroy() {}
}
