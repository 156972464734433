import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppUpdateComponent {
  constructor(
    private _swUpdate: SwUpdate,
    private _bottomSheetRef: MatBottomSheetRef<AppUpdateComponent>
  ) {}

  updateApp() {
    if (this._swUpdate && this._swUpdate.isEnabled) {
      this._swUpdate.activateUpdate().then(() => window.location.reload());
    } else {
      this._bottomSheetRef.dismiss();
    }
  }
}
