import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ICustomControl } from '@global/models/fields';
import { Observable, empty } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { SocketService } from '@admin/core/services/socket.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { ObjTextPipe } from '@admin/shared/pipes/obj-text.pipe';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  providers: [ObjTextPipe],
})
export class FormAutocompleteComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() control: ICustomControl;
  chipCtrl = new FormControl();
  @ViewChild('chipInput') chipInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  results$: Observable<any[]>;
  constructor(private _api: SocketService, private objText: ObjTextPipe) { }

  ngOnInit() {
    this.results$ = this.chipCtrl.valueChanges.pipe(
      switchMap((value) => {
        if (value) {
          const query = Object.assign(this.control.autocompleteQuery, {
            $search: value,
          });
          return this._api.find(this.control.autocompleteUrl, query, false);
        } else {
          return empty();
        }
      }),
      map((resp: any) => resp.data)
    );

    if (this.control && this.control.value) {
      this.chipCtrl.setValue(this.control.value);
    }

    if (this.control && this.control.disabled) {
      this.chipCtrl.disable();
    }

    this.form
      .get(this.control.id)
      .statusChanges.pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => {
        if (this.control && this.control.disabled) {
          this.chipCtrl.disable();
        } else {
          this.chipCtrl.enable();
        }
      });

    this.form
      .get(this.control.id)
      .valueChanges.pipe(takeUntil(componentDestroyed(this)))
      .subscribe((value) => {
        this.chipCtrl.setValue(value);
      });
  }

  get displayFn(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => item
      ? this.objText.transform(
        item,
        this.control.optionLabel,
        this.control.optionTemplate
      )
      : undefined;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.control.value = event.option.value;
    this.form.get(this.control.id).setValue(this.control.value);
  }

  ngOnDestroy() { }
}
