import { Component, OnInit, Input } from '@angular/core';
import { Student } from '@global/models/student';

@Component({
  selector: 'app-student-medical',
  templateUrl: './student-medical.component.html',
  styleUrls: ['./student-medical.component.scss'],
})
export class StudentMedicalComponent implements OnInit {
  @Input() student: Student;
  constructor() {}

  ngOnInit() {}
}
