<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let control of controls"
    [ngSwitch]="control.controlType"
  >
    <div class="pb-16" *ngSwitchCase="'checkbox'">
      <mat-checkbox [formControlName]="control.id" [checked]="control.value">{{
        control.label
      }}</mat-checkbox>
    </div>
    <mat-form-field
      [appearance]="control.appearance"
      [floatLabel]="control.floatLabel"
      class="w-100-p"
      *ngSwitchCase="'textarea'"
    >
      <mat-label>{{ control.label }}</mat-label>
      <textarea
        matInput
        [placeholder]="control.placeholder"
        [name]="control.id"
        [formControlName]="control.id"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        #textarea
      ></textarea>
      <mat-hint align="end" *ngIf="control.count"
        >{{ textarea.value.length }} / {{ control.count }}</mat-hint
      >
    </mat-form-field>
    <mat-selection-list
      [formControlName]="control.id"
      *ngSwitchCase="'list-selection'"
    >
      <h3 mat-subheader>{{ control.label }}</h3>
      <mat-list-option
        checkboxPosition="before"
        [value]="option"
        *ngFor="let option of control.options"
      >
        {{ option }}
      </mat-list-option>
    </mat-selection-list>
    <quill-editor
      class="mb-16"
      [formControlName]="control.id"
      *ngSwitchCase="'quill'"
      [modules]="quill"
    >
    </quill-editor>
    <app-form-autocomplete
      [form]="form"
      [control]="control"
      *ngSwitchCase="'autocomplete'"
    ></app-form-autocomplete>
    <app-form-chiplist
      [form]="form"
      [control]="control"
      *ngSwitchCase="'chiplist'"
    ></app-form-chiplist>
    <mat-form-field
      [appearance]="control.appearance"
      [floatLabel]="control.floatLabel"
      class="w-100-p"
      *ngSwitchCase="'select'"
    >
      <mat-label>{{ control.label }}</mat-label>
      <ng-container>
        <mat-select
          [formControlName]="control.id"
          *ngIf="control.optionsAsync; else noAsyncSelect"
        >
          <ng-container *ngIf="control.groupLabel; else notGrouped">
            <mat-optgroup
              *ngFor="let group of control.optionsAsync | async"
              [label]="group[control.groupLabel]"
            >
              <mat-option
                *ngFor="let option of group[control.groupOptions]"
                [value]="option[control.optionValue]"
              >
                {{
                  option | objText: control.optionLabel:control.optionTemplate
                }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
          <ng-template #notGrouped>
            <mat-option
              *ngFor="let option of control.optionsAsync | async"
              [value]="option[control.optionValue]"
            >
              {{ option | objText: control.optionLabel:control.optionTemplate }}
            </mat-option>
          </ng-template>
        </mat-select>
      </ng-container>
      <ng-template #noAsyncSelect>
        <mat-select [formControlName]="control.id">
          <mat-option
            *ngFor="let option of control.options"
            [value]="option[control.optionValue]"
          >
            {{ option | objText: control.optionLabel:control.optionTemplate }}
          </mat-option>
        </mat-select>
      </ng-template>
      <mat-hint *ngIf="control.hintText">{{ control.hintText }}</mat-hint>
    </mat-form-field>
    <mat-form-field
      [appearance]="control.appearance"
      [floatLabel]="control.floatLabel"
      class="w-100-p"
      *ngSwitchCase="'date'"
    >
      <mat-label>{{ control.label }}</mat-label>
      <input
        matInput
        [min]="control.minDate"
        [max]="control.maxDate"
        [matDatepicker]="datePicker"
        [placeholder]="control.placeholder"
        [name]="control.id"
        [formControlName]="control.id"
        readonly
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-hint *ngIf="control.hintText">{{ control.hintText }}</mat-hint>
    </mat-form-field>
    <mat-form-field
      [appearance]="control.appearance"
      [floatLabel]="control.floatLabel"
      class="w-100-p"
      *ngSwitchCase="'time'"
    >
      <mat-label>{{ control.label }}</mat-label>
      <input
        matInput
        [ngxTimepicker]="timePicker"
        [format]="24"
        [placeholder]="control.placeholder"
        [name]="control.id"
        [formControlName]="control.id"
        [disableClick]="true"
        readonly
      />
      <ngx-material-timepicker-toggle
        matSuffix
        [for]="timePicker"
      ></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #timePicker></ngx-material-timepicker>
      <mat-hint *ngIf="control.hintText">{{ control.hintText }}</mat-hint>
    </mat-form-field>
    <mat-form-field
      [appearance]="control.appearance"
      [floatLabel]="control.floatLabel"
      class="w-100-p"
      *ngSwitchCase="'text'"
    >
      <mat-label>{{ control.label }}</mat-label>
      <input
        matInput
        [textMask]="control.mask"
        [placeholder]="control.placeholder"
        [name]="control.id"
        [formControlName]="control.id"
        [type]="control.type"
        *ngIf="control.mask; else noMask"
      />
      <ng-template #noMask>
        <input
          matInput
          [placeholder]="control.placeholder"
          [name]="control.id"
          [formControlName]="control.id"
          [type]="control.type"
        />
      </ng-template>

      <mat-hint align="start" *ngIf="control.hintText">{{
        control.hintText
      }}</mat-hint>

      <mat-hint align="end" *ngIf="control.count"
        >{{
          form.controls[control.id].value
            ? form.controls[control.id].value.length
            : 0
        }}
        / {{ control.count }}</mat-hint
      >

      <mat-icon
        color="accent"
        matSuffix
        *ngIf="
          control.validSuffix &&
          form.controls[control.id].value &&
          form.controls[control.id].valid
        "
        >check
      </mat-icon>
      <mat-icon
        color="warn"
        matSuffix
        *ngIf="
          control.validSuffix &&
          form.controls[control.id].value &&
          !form.controls[control.id].valid
        "
        >close
      </mat-icon>
    </mat-form-field>
  </ng-container>
</ng-container>
