import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'shop',
    title: 'Shop',
    translate: 'NAV.SHOP',
    type: 'group',
    roles: ['Admin', 'Franchise'],
    children: [
      {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        icon: 'shopping_basket',
        url: '/orders',
        exactMatch: true,
        roles: ['Admin', 'Franchise'],
      },
      {
        id: 'order-deadlines',
        title: 'Order Deadlines',
        type: 'item',
        icon: 'access_time',
        url: '/orders/deadlines',
        exactMatch: true,
        roles: ['Admin'],
      },
      {
        id: 'products',
        title: 'Products',
        type: 'item',
        icon: 'shopping_basket',
        url: '/products',
        exactMatch: true,
        roles: ['Admin'],
      },
      {
        id: 'discounts',
        title: 'Discounts',
        type: 'item',
        icon: 'money_off',
        url: '/discounts',
        exactMatch: true,
        roles: ['Admin'],
      },
    ],
  },
  {
    id: 'academy-management',
    title: 'Academy',
    type: 'group',
    roles: ['Admin', 'Franchise'],
    children: [
      {
        id: 'lessons',
        title: 'Lessons',
        type: 'item',
        icon: 'school',
        url: '/lessons',
        exactMatch: true,
        roles: ['Admin', 'Franchise'],
      },
      {
        id: 'timeslots',
        title: 'Timeslots',
        type: 'item',
        icon: 'school',
        url: '/lessons/timeslots',
        exactMatch: true,
        roles: ['Admin', 'Franchise'],
      },
      {
        id: 'locations',
        title: 'Locations',
        type: 'item',
        icon: 'location_on',
        url: '/locations',
        roles: ['Admin'],
      },
      {
        id: 'sessions',
        title: 'Sessions',
        type: 'item',
        icon: 'location_on',
        url: '/session',
        roles: ['Admin'],
      },
      {
        id: 'waiting-list',
        title: 'Waiting List',
        type: 'item',
        icon: 'location_on',
        url: '/waiting-list',
        roles: ['Admin'],
      },
      {
        id: 'academy-options',
        title: 'Options',
        type: 'collapsable',
        icon: 'settings',
        roles: ['Admin'],
        children: [
          {
            id: 'rewards',
            title: 'Rewards',
            type: 'item',
            icon: 'grade',
            url: '/rewards',
            exactMatch: true,
          },
          {
            id: 'rewards',
            title: 'Reward Reasons',
            type: 'item',
            icon: 'grade',
            url: '/rewards/reasons',
            exactMatch: true,
          },
          {
            id: 'outcomes',
            title: 'Outcomes',
            type: 'item',
            icon: 'grade',
            url: '/outcomes',
          },
        ],
      },
    ],
  },
  {
    id: 'one-to-one',
    title: 'One to One',
    type: 'group',
    roles: ['Admin', 'Franchise'],
    children: [
      {
        id: 'packages',
        title: 'Packages',
        type: 'item',
        icon: 'school',
        url: '/one-to-one/packages',
        exactMatch: true,
      },
      {
        id: 'packages-students',
        title: 'Students',
        type: 'item',
        icon: 'people',
        url: '/one-to-one/students',
        exactMatch: true,
      },
    ]
  },
  {
    id: 'coaching',
    title: 'Coaching',
    type: 'group',
    roles: ['Admin', 'Coach', 'Franchise'],
    children: [
      {
        id: 'students',
        title: 'Students',
        type: 'item',
        icon: 'people',
        url: '/students',
        exactMatch: true,
      },
      {
        id: 'registers',
        title: 'Registers',
        type: 'collapsable',
        icon: 'check_box',
        children: [
          {
            id: 'registers-current',
            title: 'Current',
            type: 'item',
            icon: 'check',
            url: '/registers',
            exactMatch: true,
          },
          {
            id: 'registers-archive',
            title: 'Archive',
            type: 'item',
            icon: 'archive',
            url: '/registers/archive',
            exactMatch: true,
          },
        ],
      },
      {
        id: 'coaching-rewards',
        title: 'Rewards',
        type: 'item',
        icon: 'people',
        url: '/coaching/rewards',
        exactMatch: true,
      },
      {
        id: 'risk-assessments',
        title: 'Risk Assessments',
        type: 'item',
        icon: 'warning',
        url: '/risk-assessments',
      },
      {
        id: 'lesson-plan-activities',
        title: 'Lesson Plan/Activities',
        type: 'item',
        icon: 'format_list_bulleted',
        url: '/lesson-plan-activities',
      },
    ],
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    roles: ['Admin'],
    children: [
      {
        id: 'total-class-hours',
        title: 'Total Class Hours',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/total-class-hours',
      },
      {
        id: 'total-medal-for-pedals',
        title: 'Total Medal for Pedals',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/total-medal-for-pedals',
      },
      {
        id: 'total-points',
        title: 'Total Points',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/total-points',
      },
      {
        id: 'total-attendance',
        title: 'Total Attendance',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/total-attendance',
      },
      {
        id: 'outcomes-report',
        title: 'Outcomes',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/outcomes',
      },
      {
        id: 'rewards-report',
        title: 'Rewards',
        type: 'item',
        icon: 'bar_chart',
        url: '/reports/rewards',
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    roles: ['Admin'],
    children: [
      {
        id: 'blog',
        title: 'Blog',
        type: 'item',
        icon: 'pages',
        url: '/blog',
      },
      {
        id: 'page',
        title: 'Pages',
        type: 'item',
        icon: 'pages',
        url: '/pages',
      },
      {
        id: 'category',
        title: 'Categories',
        type: 'item',
        icon: 'category',
        url: '/categories',
      },
      {
        id: 'resource',
        title: 'Resources',
        type: 'item',
        icon: 'pages',
        url: '/resource',
      },
      {
        id: 'franchise',
        title: 'Franchises',
        type: 'item',
        icon: 'pages',
        url: '/franchise',
      },
    ],
  },
  {
    id: 'user-management',
    title: 'Users',
    type: 'group',
    roles: ['Admin'],
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'supervisor_account',
        url: '/users',
      },
      {
        id: 'members',
        title: 'Members',
        type: 'item',
        icon: 'supervisor_account',
        url: '/members',
      },
    ],
  },
];
