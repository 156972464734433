import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    DropzoneModule,
    DropzoneConfigInterface,
    DROPZONE_CONFIG,
} from 'ngx-dropzone-wrapper';
import { environment } from '@env/environment';
import { AppUpdateComponent } from './components/app-update/app-update.component';
import { MatButtonModule } from '@angular/material/button';
import { FormlyCustomModule } from './modules/formly-custom/formly-custom.module';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: `${environment.host}/upload`,
    maxFilesize: 50,
    acceptedFiles: 'image/*',
};

@NgModule({
    declarations: [AppUpdateComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        DropzoneModule,
        FormlyCustomModule,
    ],
    exports: [AppUpdateComponent],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ]
})
export class CoreModule { }
