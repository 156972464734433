import { Component, OnInit, Input } from '@angular/core';
import { diff } from './diff-text-util';

@Component({
  selector: 'app-diff-text',
  templateUrl: './diff-text.component.html',
  styleUrls: ['./diff-text.component.scss'],
})
export class DiffTextComponent implements OnInit {
  @Input() originalText: string;
  @Input() newText: string;
  @Input() oldText: string;
  diffText: string;
  constructor() { }

  ngOnInit() {
    if (this.newText !== this.oldText) {
      this.diffText = diff(this.oldText, this.newText);
    } else {
      this.diffText = this.originalText;
    }
  }
}
