import { Component, Input } from '@angular/core';
import { Address } from '@global/models/address';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address: Address;
  @Input() email = true;
  constructor() {}
}
