import { Injectable } from '@angular/core';
import { ICustomControl } from '@global/models/fields';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  emptyValue(control: ICustomControl, prefix: string, selector: string) {
    return prefix ? `${prefix}${control.id}.${selector}` : null;
  }

  private _isArray(subject: any[] | any): boolean {
    return typeof subject === 'object' && subject.constructor === Array;
  }

  public addControls(
    controls: ICustomControl[],
    form: FormGroup,
    prefix?: string
  ): FormGroup {
    const getControls = this.configureControls(controls, prefix);
    Object.keys(getControls).forEach((key: any) => {
      form.addControl(key, new FormControl(...getControls[key]));
    });

    return form;
  }

  public configureControls(controls: ICustomControl[], prefix?: string): any[] {
    const group: any = {};
    if (this._isArray(controls)) {
      controls.forEach((control: ICustomControl) => {
        control.label = control.label
          ? control.label
          : this.emptyValue(control, prefix, 'label');
        control.placeholder = control.placeholder
          ? control.placeholder
          : this.emptyValue(control, prefix, 'placeholder');
        control.hintText = control.hintText
          ? control.hintText
          : this.emptyValue(control, prefix, 'hintText');
        control.type = control.type ? control.type : `${prefix}${control.id}`;
        control.appearance = control.appearance
          ? control.appearance
          : 'outline';

        control.floatLabel = control.floatLabel ? control.floatLabel : 'always';
        group[control.id] = this.configureControl(control);
      });
    }
    return group;
  }

  public configureControl(control: ICustomControl): any[] {
    // First of all we add initial value. Mock 'null' value if haven't stored value
    const output: any[] = [
      {
        value:
          control.value ||
          typeof control.value === 'boolean' ||
          control.value === 0
            ? control.value
            : null,
        disabled: control.disabled
      }
    ];

    // Initialize empty validators array.
    const validators: any[] = [];

    if (control.validators) {
      if (this._isArray(control.validators)) {
        control.validators.forEach(validatorName => {
          const validator: any = this._setValidator(control, validatorName);

          if (validator) {
            validators.push(validator);
          }
        });
      } else if (typeof control.validators === 'string') {
        this._setValidator(control, control.validators as string);
      }
    }

    // Add an array of validators to the end of the control array.
    output.push(validators);
    return output;
  }

  private _setValidator(input: ICustomControl, key: string) {
    // Check if validator should accept parameters.
    const isValidatorTakesValue: boolean =
      key === 'min' ||
      key === 'max' ||
      key === 'minLength' ||
      key === 'maxLength' ||
      key === 'pattern';

    if (isValidatorTakesValue) {
      if (!input[key]) {
        // Check if input value exist to each validator.
        this._warning(key);
        return true;
      }
    } else {
      return Validators[key]; // If validator shouldn't accept any parameters.
    }

    return Validators[key](input[key]);
  }

  private _warning(key: string) {
    // This is just a warning method.
    let prefix = '';
    switch (key) {
      case 'min': {
        prefix = 'Min length number';
        break;
      }
      case 'max': {
        prefix = 'Max number';
        break;
      }
      case 'minLength': {
        prefix = 'Min length number';
        break;
      }
      case 'maxLength': {
        prefix = 'Max length number';
        break;
      }
      case 'pattern': {
        prefix = 'Pattern string';
        break;
      }
      default: {
        prefix = 'Some value';
        break;
      }
    }
    console.warn(
      `'${prefix}' doesn't exist in control configuration. Please, check '${key}' configuration.`
    );
  }
}
