import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Underline from '@editorjs/underline';
import Table from '@editorjs/table';

@Component({
  selector: 'app-editorjs',
  templateUrl: './editorjs.component.html',
  styleUrls: ['./editorjs.component.scss'],
})
export class EditorjsComponent implements AfterViewInit {
  @Input() data: any;
  @Input() folder: string;
  @Input() placeholder: string;
  @ViewChild('editorJs', { static: true }) el: ElementRef;
  public editor: EditorJS;
  constructor(private httpClient: HttpClient) {}

  ngAfterViewInit() {
    const that = this;
    this.editor = new EditorJS({
      holder: this.el.nativeElement,
      placeholder: this.placeholder,
      tools: {
        underline: Underline,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        quote: Quote,
        header: {
          class: Header,
          config: {
            placeholder: 'Enter a header',
            levels: [2, 3, 4, 5, 6],
            defaultLevel: 2,
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        table: {
          class: Table
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: true,
            },
          },
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              accept: 'image/*',
              byFile: `${environment.host}/upload`, // Your backend file uploader endpoint
            },
            field: 'file',
            uploader: {
              uploadByFile(file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('folder', this.folder);
                return that.httpClient
                  .post(`${environment.host}/upload`, formData, {
                    reportProgress: true,
                    observe: 'events',
                  })
                  .toPromise()
                  .then((resp: any) => {
                    return {
                      success: '1',
                      file: {
                        url: `${environment.image_cdn}/${resp.body.public_id}`,
                        ...resp.body,
                      },
                    };
                  });
              },
            },
          },
        },
      },
      data: this.data ? this.data : {},
    });
  }
}
