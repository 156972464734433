<mat-chip-list #chipList>
  <mat-chip
    *ngFor="let item of formControl.value; let i = index"
    [selectable]="selectable"
    [removable]="removable"
    (removed)="remove(i)"
  >
    {{ item | objText: to.optionLabel:to.optionTemplate }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
  <input
    [formControl]="itemControl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />
</mat-chip-list>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="selected($event)"
>
  <mat-option *ngFor="let item of filter | async" [value]="item">
    {{ item | objText: to.optionLabel:to.optionTemplate }}
  </mat-option>
</mat-autocomplete>
