export const locale = {
  lang: 'en',
  data: {
    NAV: {
      DASHBOARD: 'Dashboard',
      SHOP: 'Shop',
      APPLICATIONS: 'Applications',
      SAMPLE: {
        TITLE: 'Sample',
        BADGE: '25'
      }
    }
  }
};
