<input
  *ngIf="type !== 'number'; else numberTmp"
  matInput
  [id]="id"
  [type]="type || 'text'"
  [readonly]="to.readonly"
  [required]="to.required"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabindex]="to.tabindex"
  [placeholder]="to.placeholder"
  [textMask]="to.mask"
/>
<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    type="number"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [tabindex]="to.tabindex"
    [placeholder]="to.placeholder"
    [textMask]="to.mask"
  />
</ng-template>
