import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs/operators';

import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { FieldType } from '@ngx-formly/material/form-field';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent extends FieldType implements AfterViewInit {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  signaturePadOptions = {
    backgroundColor: 'rgb(245, 245, 245)',
    canvasWidth: 275,
    canvasHeight: 150,
  };

  _signature = null;

  isNew = true;

  get signature() {
    return this._signature;
  }

  set signature(value) {
    this._signature = value;
  }

  constructor(protected _route: ActivatedRoute) {
    super();
  }

  ngAfterViewInit() {
    this._route.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params: any) => {
        if (params['id']) {
          this.isNew = false;
          this.signaturePad.off();
        }
      });

    if (this.formControl.value && !this.isNew) {
      this.signature = this.signaturePad.fromDataURL(this.formControl.value);
    }
  }

  drawBegin() { }

  drawComplete() {
    this.signature = this.signaturePad.toDataURL('image/png', 1);
    this.formControl.setValue(this.signature);
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signature = '';
    this.formControl.setValue(null);
  }
}
