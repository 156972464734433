import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@admin/core/services/form.service';
import { ICustomControl } from '@global/models/fields';
import { Address } from '@global/models/address';
import { SocketService } from '@admin/core/services/socket.service';
import { Observable } from 'rxjs';
import { Country } from '@global/models/country';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() address: Address;
  controls: ICustomControl[];
  countries$: Observable<Country[]>;
  private countries: Country[];
  constructor(private _formService: FormService, _api: SocketService) {
    this.countries$ = _api
      .find('country', {
        $select: ['name', 'cca2'],
        $sort: { name: 1 }
      })
      .pipe(tap((countries: Country[]) => (this.countries = countries)));
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'address_line_1',
        label: 'Address line 1',
        value: this.address ? this.address.address_line_1 : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'address_line_2',
        label: 'Address line 2',
        value: this.address ? this.address.address_line_2 : null,
        controlType: 'text'
      },
      {
        id: 'address_line_3',
        label: 'Address line 3',
        value: this.address ? this.address.address_line_3 : null,
        controlType: 'text'
      },
      {
        id: 'city',
        label: 'City',
        value: this.address ? this.address.city : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'region',
        label: 'Region',
        value: this.address ? this.address.region : null,
        controlType: 'text'
      },
      {
        id: 'country',
        label: 'Country',
        value: this.address ? this.address.country : 'United Kingdom',
        controlType: 'select',
        optionsAsync: this.countries$,
        optionValue: 'name',
        optionLabel: 'name',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'country_code',
        label: 'Country Code',
        value: this.address ? this.address.country_code : 'GB',
        controlType: 'hidden'
      },
      {
        id: 'postcode',
        label: 'Postcode',
        value: this.address ? this.address.postcode : null,
        controlType: 'text'
      }
    ];
    this._formService.addControls(this.controls, this.form);
    this.form
      .get('country')
      .valueChanges.pipe(takeUntil(componentDestroyed(this)))
      .subscribe((name: string) => {
        const country = this.countries.find(c => c.name === name);
        this.form.get('country_code').setValue(country.cca2);
      });
  }

  ngOnDestroy() {}
}
