import { Directive } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';

const selector = `[fxLayout.print]`;
const inputs = ['fxLayout.print'];

@Directive({ selector, inputs })
export class CustomLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
