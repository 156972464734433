import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { Observable, empty } from 'rxjs';
import { SocketService } from '@admin/core/services/socket.service';
import { switchMap, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-form-chiplist',
  templateUrl: './form-chiplist.component.html',
  styleUrls: ['./form-chiplist.component.scss']
})
export class FormChiplistComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() control: ICustomControl;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl();
  @ViewChild('chipInput') chipInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  results$: Observable<any[]>;
  constructor(private _api: SocketService) {}

  ngOnInit() {
    this.results$ = this.chipCtrl.valueChanges.pipe(
      switchMap(value => {
        if (value) {
          const query = Object.assign(this.control.autocompleteQuery, {
            $search: value
          });
          return this._api.find(this.control.autocompleteUrl, query, false);
        } else {
          return empty();
        }
      }),
      map((resp: any) => resp.data)
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const values = this.form.get(this.control.id).value;
    values.push(event.option.value);
    this.control.value = values;
    this.form.get(this.control.id).setValue(this.control.value);
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null);
  }

  remove(value: string): void {
    const values = this.form.get(this.control.id).value;
    const filteredValues = values.filter(
      v => v[this.control.optionValue] !== value[this.control.optionValue]
    );
    this.control.value = filteredValues;
    this.form.get(this.control.id).setValue(this.control.value);
  }
}
