import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Location } from '@global/models/location';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyCustomService } from '@admin/core/modules/formly-custom/formly-custom.service';
import { map } from 'rxjs/operators';
import { SocketService } from '@admin/core/services/socket.service';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() location: Location;
  @Input() wait: boolean;
  dropzoneConfig = {
    params: {
      folder: 'location',
    },
  };
  model = {};
  fields: FormlyFieldConfig[];
  constructor(
    private formlyCustomService: FormlyCustomService,
    private _api: SocketService
  ) { }

  ngOnInit() {
    this.model = { ...this.model, ...this.location };
    this.setUpForm();
  }

  filterFranchises(value: string) {
    return this._api
      .find('franchise', { $search: value })
      .pipe(map((response: any) => response.data));
  }

  setUpForm() {
    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: 'Name',
          required: true,
        },
      },
      {
        key: 'equipment',
        type: 'quill',
        templateOptions: {
          label: 'Equipment',
        },
      },
      {
        key: 'image',
        type: 'image',
        templateOptions: {
          title: 'Location Image',
          config: {
            params: {
              folder: 'location',
            },
          },
        },
      },
      {
        key: 'address',
        wrappers: ['panel'],
        templateOptions: { label: 'Address' },
        fieldGroup: this.formlyCustomService.getAddressForm(false),
      },
      {
        key: 'contact_details',
        wrappers: ['panel'],
        templateOptions: { label: 'Contact Details' },
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Name',
            },
          },
          {
            key: 'phone_number',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Phone Number',
            },
          },
        ],
      },
      {
        key: 'location',
        fieldGroup: [
          {
            key: 'type',
            type: 'input',
            defaultValue: 'Point',
            templateOptions: {
              label: 'Type',
            },
            hideExpression: true,
          },
          {
            key: 'coordinates',
            type: 'input',
            templateOptions: {
              label: 'Coordinates',
            },
            hideExpression: true,
          },
        ],
      },
      // {
      //   key: 'franchise',
      //   type: 'autocomplete',
      //   templateOptions: {
      //     required: true,
      //     label: 'Franchise',
      //     filter: (value: string) => this.filterFranchises(value),
      //     optionLabel: '{name}',
      //     optionTemplate: true,
      //   },
      // },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location && !changes.location.firstChange && this.wait) {
      this.setUpForm();
    }
  }
}
