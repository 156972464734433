import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppStorage } from '@global/forStorage/universal.inject';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    private toastr: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const matches = req.url.match(
      /^https?\:\/\/(?!(?:www\.)?(?:cloudinary\.com))([^\/:?#]+)(?:[\/:?#]|$)/i
    );
    const domain = matches && matches[1];

    if (domain !== 's3.eu-central-1.amazonaws.com') {
      req = req.clone({
        withCredentials: true,
      });

      const token: string = this.appStorage.getItem(environment.cookie_key);

      if (token) {
        req = req.clone({
          setHeaders: { Authorization: token },
        });
      }
    }
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          const error = err.error;

          this.toastr.error(
            error && error.message ? error.message : err.message
          );
        }

        return throwError(err);
      })
    );
  }
}
