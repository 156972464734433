import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseModule } from '@fuse/fuse.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { CookieModule } from 'ngx-cookie';

import { fuseConfig } from '@admin/fuse-config';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import {
  AppInitService,
  appInitServiceInitProvider,
  appAuthServiceInitProvider,
  appCountryServiceInitProvider,
} from './core/services/app-init.service';
import { AppStorage } from '@global/forStorage/universal.inject';
import { CookieStorage } from '@global/forStorage/browser.storage';
import { JWTInterceptor } from './jwt.service';
import { Angulartics2Module } from 'angulartics2';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { environment } from '@env/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatBottomSheetModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    CoreModule,
    CookieModule.forRoot(),
    Angulartics2Module.forRoot(),
    QuillModule.forRoot(),
    NgSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
  ],
  providers: [
    AppInitService,
    appInitServiceInitProvider,
    appAuthServiceInitProvider,
    appCountryServiceInitProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    { provide: AppStorage, useClass: CookieStorage },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
