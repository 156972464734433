import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ListViewComponent } from '../list-view/list-view.component';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Observable, fromEvent, of } from 'rxjs';
import { BatchService } from '@admin/core/services/batch.service';
import { SocketService } from '@admin/core/services/socket.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { sortDirection } from '@admin/core/types/clancy-interface';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-base-list',
  template: ''
})
export class BaseListComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ListViewComponent) listView: ListViewComponent;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(FusePerfectScrollbarDirective) scrollbar: FusePerfectScrollbarDirective;
  items: any[];
  endpoint: string;
  resultsLength = 0;
  isLoadingResults = true;
  pageSize = 25;
  defaultSort: any = {
    weight: 1
  };
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  search: Observable<any>;

  private _baseQuery: any;

  constructor(
    protected batch: BatchService,
    protected api: SocketService,
    protected _fuseProgressBarService: FuseProgressBarService,
    protected _matDialog: MatDialog
  ) { }

  get adjustment() {
    return this.paginator.pageIndex * this.pageSize;
  }

  get baseQuery() {
    return this._baseQuery;
  }

  set baseQuery(query: any) {
    this._baseQuery = query;
  }

  get query() {
    let sort: any = this.defaultSort;

    if (this.sort && this.sort.direction) {
      sort = {
        [this.sort.active]: sortDirection[this.sort.direction]
      };
    }

    const query: any = {
      ...this.baseQuery,
      $sort: sort,
      $limit: this.pageSize,
      $skip: this.pageSize * this.paginator.pageIndex
    };

    if (this.filter && this.filter.nativeElement.value) {
      query.$search = this.filter.nativeElement.value;
    }

    return query;
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => (this.paginator.pageIndex = 0));
    if (this.filter) {
      this.search = fromEvent(this.filter.nativeElement, 'keyup').pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(componentDestroyed(this))
      );
    }
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    const prevIndex = this.items.findIndex(d => d === event.item.data);
    moveItemInArray(this.items, prevIndex, event.currentIndex);
    this.table.renderRows();
    this.updateOrder();
  }

  updateOrder() {
    for (let index = 0; index < this.items.length; index++) {
      const item = this.items[index];
      if (item.weight !== index) {
        const data = [
          `patch`,
          `${this.endpoint}`,
          item._id,
          { weight: index + this.adjustment }
        ];
        this.batch.addToBatch.next([...this.batch.addToBatch.getValue(), data]);
        item.weight = index + this.adjustment;
      }
    }
  }

  trackBy(index: number, item: any) {
    return item._id;
  }

  startLoading() {
    this._fuseProgressBarService.setMode('query');
    this._fuseProgressBarService.show();
    this.pageSize = this.paginator.pageSize;
    return this.api.find(this.endpoint, this.query);
  }

  endLoading(data: any) {
    this._fuseProgressBarService.hide();
    this.resultsLength = data.total;
    if (this.scrollbar) {
      this.scrollbar.scrollToTop();
    }
    return data.data;
  }

  errorLoading() {
    this._fuseProgressBarService.hide();
    return of([]);
  }

  deleteItem(id: any) {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete this item?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.remove(this.endpoint, id);
        this.items = this.items.filter(i => i._id !== id);
        this.table.renderRows();
      }
      this.confirmDialogRef = null;
    });
  }

  ngOnDestroy() { }
}
