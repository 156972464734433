import { Injectable } from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { environment } from '@env/environment';

@Injectable()
export class CookieStorage implements Storage {
  [index: number]: string;
  [key: string]: any;
  length: number;
  secure: boolean;
  options: CookieOptions;

  constructor(private cookieService: CookieService) {}

  clear(): void {
    this.cookieService.removeAll();
  }

  getItem(key: string): string {
    return this.cookieService.get(key);
  }

  key(index: number): string {
    return this.cookieService.getAll().propertyIsEnumerable[index];
  }

  removeItem(key: string): void {
    this.cookieService.remove(key, this.options);
  }

  setItem(key: string, data: string): void {
    this.cookieService.put(key, data, {
      expires: new Date(Date.now() + 365 * 86400000),
      domain: environment.cookie,
      path: '/',
      secure: true
    });
  }
}
