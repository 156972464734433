<ng-container [formGroup]="form">
  <mat-form-field [appearance]="control.appearance" [floatLabel]="control.floatLabel" class="w-100-p">
    <mat-label>{{control.label}}</mat-label>
    <input matInput [placeholder]="control.placeholder" [formControl]="chipCtrl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
      <mat-option *ngFor="let option of results$ | async" [value]="option">
        {{option | objText:control.optionLabel:control.optionTemplate}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
