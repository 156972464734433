export const locale = {
  lang: 'en',
  data: {
    publishing: {
      form: {
        published: {
          label: 'Published',
          placeholder: '',
          hintText: ''
        },
        slug: {
          label: 'Slug',
          placeholder: '',
          hintText: ''
        }
      }
    },
    seo: {
      form: {
        title: {
          label: 'Seo Title',
          placeholder: '',
          hintText: ''
        },
        description: {
          label: 'Seo description',
          placeholder: '',
          hintText: ''
        },
        shopping_title: {
          label: 'Google Shopping Title',
          placeholder: '',
          hintText: ''
        },
        shopping_description: {
          label: 'Google Shopping Description',
          placeholder: '',
          hintText: ''
        },
        shopping_category: {
          label: 'Google Shopping Category',
          placeholder: '',
          hintText: ''
        },
        gtin: {
          label: 'Google Shopping GTIN',
          placeholder: '',
          hintText: ''
        },
        shopping_exclude: {
          label: 'Exclude from shopping feed',
          placeholder: '',
          hintText: ''
        }
      }
    }
  }
};
