<div class="profile-box info-box general">
  <header class="accent">
    <div class="title">Medical/Emergency</div>
  </header>
  <div class="content">
    <ng-container
      *ngIf="student.consent && student.consent.signature; else oldMedical"
    >
      <div class="info-line">
        <div class="title">First Contact</div>
        <div class="info">
          Name: {{ student.first_contact.name }} <br />Day phone:
          {{ student.first_contact.day_phone }} <br />Evening phone:
          {{ student.first_contact.evening_phone }} <br />Email:
          {{ student.first_contact.email }} <br />Relationship:
          {{ student.first_contact.relationship }}
        </div>
      </div>
      <div class="info-line">
        <div class="title">Second Contact</div>
        <div class="info">
          Name: {{ student.second_contact.name }} <br />Day phone:
          {{ student.second_contact.day_phone }} <br />Evening phone:
          {{ student.second_contact.evening_phone }} <br />Email:
          {{ student.second_contact.email }} <br />Relationship:
          {{ student.second_contact.relationship }}
        </div>
      </div>
      <div
        class="info-line"
        *ngIf="student.medical && student.medical.disability === 'Yes'"
      >
        <div class="title">Disabilities</div>
        <div
          class="info"
          [innerHTML]="student.medical.disability_details"
        ></div>
      </div>
      <div
        class="info-line"
        *ngIf="
          student.medical &&
          student.medical.conditions &&
          student.medical.conditions.length
        "
      >
        <div class="title">Conditions</div>
        <div class="info" [innerHTML]="student.medical.conditions"></div>
      </div>
      <div
        class="info-line"
        *ngIf="student.medical && student.medical.medical_treatments"
      >
        <div class="title">Medical Treatments</div>
        <div
          class="info"
          [innerHTML]="student.medical.medical_treatments"
        ></div>
      </div>
      <div
        class="info-line"
        *ngIf="student.medical && student.medical.allergic === 'Yes'"
      >
        <div class="title">Allergies</div>
        <div class="info" [innerHTML]="student.medical.allergic_details"></div>
      </div>
      <div
        class="info-line"
        *ngIf="
          student.medical &&
          student.medical.medications &&
          student.medical.medications.length
        "
      >
        <div class="title">Medications</div>
        <div class="info">
          <table class="mat-table w-100-p">
            <tr class="mat-row mat-header-row">
              <th class="mat-header-cell">Name</th>
              <th class="mat-header-cell">Method</th>
              <th class="mat-header-cell">Instructions</th>
              <th class="mat-header-cell">Reason</th>
            </tr>
            <tr *ngFor="let medication of student.medical.medications">
              <td>{{ medication.name }}</td>
              <td>{{ medication.method }}</td>
              <td>{{ medication.instructions }}</td>
              <td>{{ medication.reason }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="info-line" *ngIf="student.medical && student.medical.doctor">
        <div class="title">Doctor</div>
        <div class="info" [innerHTML]="student.medical.doctor"></div>
      </div>
    </ng-container>
    <ng-template #oldMedical>
      <div class="info-line">
        <div class="title">Important Information</div>
        <div class="info">
          <app-diff-text
            [originalText]="student.information"
            [newText]="
              student.history &&
              student.history.length > 1 &&
              student.history[0].data.information
            "
            [oldText]="
              student.history &&
              student.history.length > 1 &&
              student.history[1].data.information
            "
          ></app-diff-text>
        </div>
      </div>
      <div class="info-line">
        <div class="title">Emergency Contact</div>
        <div class="info">
          <span [innerHtml]="student.emergency_contact"></span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
