<div style="display: flex;">
  <input
    matInput
    [disableClick]="to.disableClick"
    [format]="to.format"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [ngxTimepicker]="timePicker"
    [placeholder]="to.placeholder"
    [readonly]="to.readonly"
  />
  <ngx-material-timepicker-toggle
    matSuffix
    [for]="timePicker"
  ></ngx-material-timepicker-toggle>
  <ngx-material-timepicker #timePicker></ngx-material-timepicker>
</div>
