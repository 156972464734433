import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
})
export class InputMaskComponent extends FieldType {
  @ViewChild(MatInput) formFieldControl!: MatInput;
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => this.field && this.showError,
  };
  get type() {
    return this.to.type || 'text';
  }
}
