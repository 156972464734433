<mat-card *ngIf="image; else uploadBlock">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <img mat-card-image [appLazysizes]="image.public_id" />
  <h6 *ngIf="image && image.type === 'file'">{{ image.name }}</h6>
  <mat-card-content>
    <mat-form-field class="mt-16 w-100-p" appearance="outline" *ngIf="image && image.type === 'image'">
      <mat-label>Alt Text</mat-label>
      <input matInput [formControl]="altText" [value]="image.alt" />
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button mat-button (click)="updateImage()" *ngIf="image && image.type === 'image'">
      Update
    </button>
    <button mat-raised-button (click)="removeImage()">Remove</button>
  </mat-card-actions>
</mat-card>
<ng-template #uploadBlock>
  <dropzone [config]="dropzoneConfig" [message]="message" (success)="onUploadSuccess($event)"
    [useDropzoneClass]="false"></dropzone>
</ng-template>