import { Directive } from '@angular/core';
import { FlexDirective } from '@angular/flex-layout';

const selector = `[fxFlex.print]`;
const inputs = ['fxFlex.print'];

@Directive({ selector, inputs })
export class CustomFlexDirective extends FlexDirective {
  protected inputs = inputs;
}
