import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@admin/core/services/form.service';

@Component({
  selector: 'app-seo-form',
  templateUrl: './seo-form.component.html',
  styleUrls: ['./seo-form.component.scss'],
})
export class SeoFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() item: any;
  @Input() shopping: boolean;
  seoGroup: FormGroup;
  controls: ICustomControl[];
  private _prefix = 'seo.form.';

  constructor(private _fb: FormBuilder, private _formService: FormService) {}

  ngOnInit() {
    const hasSeo = this.item && this.item.seo;

    this.controls = [
      {
        id: 'title',
        label: 'Title',
        value: hasSeo ? this.item.seo.title : null,
        controlType: 'text',
        count: 55,
      },
      {
        id: 'description',
        label: 'Description',
        value: hasSeo ? this.item.seo.description : null,
        controlType: 'textarea',
        count: 160,
      },
    ];

    if (this.shopping) {
      this.controls = [
        ...this.controls,
        {
          id: 'shopping_title',
          value:
            hasSeo && this.item.seo.shopping_title
              ? this.item.seo.shopping_title
              : null,
        },
        {
          id: 'shopping_description',
          value:
            hasSeo && this.item.seo.shopping_description
              ? this.item.seo.shopping_description
              : null,
          controlType: 'textarea',
        },
        {
          id: 'shopping_category',
          value:
            hasSeo && this.item.seo.shopping_category
              ? this.item.seo.shopping_category
              : null,
        },
        {
          id: 'gtin',
          value: hasSeo && this.item.seo.gtin ? this.item.seo.gtin : null,
        },
        {
          id: 'shopping_exclude',
          value: hasSeo && this.item.seo.shopping_exclude ? true : false,
          controlType: 'checkbox',
        },
      ];
    }

    this.seoGroup = this._fb.group(
      this._formService.configureControls(this.controls)
    );
    this.parentForm.addControl('seo', this.seoGroup);
  }

  get seoData() {
    return this.parentForm.get('seo') as FormGroup;
  }
}
