import { Injectable } from '@angular/core';
import feathers from '@feathersjs/feathers';
import authentication from '@feathersjs/authentication-client';
import * as io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import { environment } from '@env/environment';
import { CookieStorage } from 'cookie-storage';
import { feathersRx } from '@admin/core/services/feathers-rx';

@Injectable({
  providedIn: 'root',
})
export class FeathersService {
  private _feathers: any;
  private _socket: any;
  constructor() {
    this._socket = io(environment.host, {
      transports: ['websocket'],
      upgrade: false,
    });
    this._feathers = feathers();
    this._feathers.configure(feathersRx());
    this._feathers.configure(socketio(this._socket, { timeout: 30000 })); // add socket.io plugin

    this._feathers.configure(
      authentication({
        storageKey: environment.cookie_key,
        storage: new CookieStorage({
          domain: environment.admin,
          path: '/',
          expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
          secure: true,
        }),
      })
    );
  }

  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  // expose authentication
  public authenticate(credentials?): Promise<any> {
    return this._feathers.authenticate(credentials);
  }

  // expose logout
  public logout() {
    return this._feathers.logout();
  }
}
