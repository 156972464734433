import { APP_INITIALIZER, Injectable, Injector } from '@angular/core';
import { FeathersService } from './feathers.service';
import { AuthService } from './auth.service';
import { SocketService } from './socket.service';
import { Country } from '@global/models/country';

@Injectable()
export class AppInitService {
  countries: Country[];
  constructor(private feathers: FeathersService, private _injector: Injector) {}

  public get auth(): AuthService {
    return this._injector.get(AuthService);
  }

  public get api(): SocketService {
    return this._injector.get(SocketService);
  }

  authenticate() {
    return this.feathers.authenticate().catch((err) => err);
  }

  checkUser() {
    return new Promise<void>((resolve, reject) => {
      this.auth.redirectNonAdmin();
      resolve();
    });
  }

  getCountries() {
    return this.api
      .find('country')
      .toPromise()
      .then((countries: Country[]) => {
        this.countries = countries;
      });
  }
}

export function appInitServiceFactory(provider: AppInitService) {
  return () => provider.authenticate();
}

export const appInitServiceInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInitServiceFactory,
  deps: [AppInitService],
  multi: true,
};

export function appAuthServiceFactory(provider: AppInitService) {
  return () => provider.checkUser();
}

export const appAuthServiceInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: appAuthServiceFactory,
  deps: [AppInitService],
  multi: true,
};

export function appCountryServiceFactory(provider: AppInitService) {
  return () => provider.getCountries();
}

export const appCountryServiceInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: appCountryServiceFactory,
  deps: [AppInitService],
  multi: true,
};
