import { Component, OnInit, Input } from '@angular/core';
import { Student } from '@global/models/student';

@Component({
  selector: 'app-student-general',
  templateUrl: './student-general.component.html',
  styleUrls: ['./student-general.component.scss'],
})
export class StudentGeneralComponent implements OnInit {
  @Input() student: Student;
  constructor() {}

  ngOnInit() {}
}
