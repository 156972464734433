import { Injectable } from '@angular/core';
import AddressForm from './forms/address';
import { AppInitService } from '@admin/core/services/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class FormlyCustomService {
  constructor(private initService: AppInitService) {}

  getAddressForm(name = true, email = false, phone = false) {
    return AddressForm(this.initService.countries, name, email, phone);
  }
}
