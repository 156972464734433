import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Image } from '@global/models/image';
import { AuthService } from '@admin/core/services/auth.service';
import { SocketService } from '@admin/core/services/socket.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-multi-image-upload',
  templateUrl: './multi-image-upload.component.html',
  styleUrls: ['./multi-image-upload.component.scss']
})
export class MultiImageUploadComponent implements OnInit, OnDestroy {
  @Input() images: Image[] = [];
  @Input() config: DropzoneConfigInterface;
  dropzoneConfig: DropzoneConfigInterface;
  @Input() title: string;
  @Output() imagesUpdated = new EventEmitter<Image[]>();
  message =
    '<i class="material-icons s-48">cloud_upload</i><h5>Click or drag here to upload</h5>';
  constructor(private auth: AuthService, protected _api: SocketService) {
    this.dropzoneConfig = {
      headers: {
        Authorization: this.auth.getToken()
      },
      uploadMultiple: false,
      autoReset: 2000
    };
  }

  ngOnInit() {
    this.dropzoneConfig = Object.assign(this.dropzoneConfig, this.config);
  }

  onUploadSuccess(resp: any) {
    this.images.push(resp[1]);
    this.imagesUpdated.emit(this.images);
  }

  removeImage(image: Image) {
    this._api
      .delete('image', image._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => {
        this.images = this.images.filter(i => i._id !== image._id);
        this.imagesUpdated.emit(this.images);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    for (let index = 0; index < this.images.length; index++) {
      const image = this.images[index];
      image.weight = index;
    }
    this.imagesUpdated.emit(this.images);
  }

  ngOnDestroy() { }
}
