import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, switchMap } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/material';
import { ObjTextPipe } from '@admin/shared/pipes/obj-text.pipe';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent
  extends FieldType
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  itemControl = new FormControl();
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filter: Observable<any>;

  constructor(private objText: ObjTextPipe) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.filter = this.itemControl.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string) => {
        return !value ? EMPTY : this.to.filter(value);
      })
    );
  }

  // get empty() {
  //   return this.formControl.value.length === 0;
  // }

  get displayFn(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => {
      return item
        ? this.objText.transform(
            item,
            this.to.optionLabel,
            this.to.optionTemplate
          )
        : undefined;
    };
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.formControl.setValue([...this.formControl.value, event.option.value]);

    this.itemControl.setValue(null);
  }

  public remove(i: number): void {
    const value = this.formControl.value;

    this.formControl.setValue([
      ...value.slice(0, i),
      ...value.slice(i + 1, value.length),
    ]);
    this.formControl.markAsTouched();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.matAutocomplete as any)._formField = this.formField;
  }
}
