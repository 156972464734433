import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SocketService } from '@admin/core/services/socket.service';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormService } from '@admin/core/services/form.service';
import { ICustomControl } from '@global/models/fields';

@Component({
  selector: 'app-publishing-form',
  templateUrl: './publishing-form.component.html',
  styleUrls: ['./publishing-form.component.scss'],
})
export class PublishingFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() item: any;
  @Input() hasSlug: boolean;
  @Input() endpoint: string;
  controls: ICustomControl[];
  constructor(
    private _service: SocketService,
    private _formService: FormService
  ) {}

  ngOnInit() {
    this.controls = [
      {
        id: 'published',
        label: 'Published',
        controlType: 'checkbox',
        value:
          this.item && this.item.hasOwnProperty('published')
            ? this.item.published
            : true,
      },
    ];

    if (this.hasSlug && this.endpoint) {
      this.controls.push({
        id: 'slug',
        label: 'Slug',
        controlType: 'text',
        validSuffix: true,
        value: this.item && this.item.slug ? this.item.slug : null,
        hintText: 'Leave blank to auto generate slug when saved',
      });
      this._formService.addControls(this.controls, this.parentForm);

      this.parentForm.controls.slug.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) =>
            this._service.find(
              this.endpoint,
              {
                slug: value,
              },
              false
            )
          ),
          takeUntil(componentDestroyed(this))
        )
        .subscribe((resp: any) => {
          if (
            resp.total === 0 ||
            (this.item && this.item.slug === this.parentForm.get('slug').value)
          ) {
            this.parentForm.get('slug').setErrors(null);
          } else {
            this.parentForm.get('slug').setErrors({ slug_taken: true });
          }
        });
    } else {
      this._formService.addControls(this.controls, this.parentForm);
    }
  }

  ngOnDestroy() {}
}
