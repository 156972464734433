<div>
  <signature-pad
    [options]="signaturePadOptions"
    (onEndEvent)="drawComplete()"
  ></signature-pad>
</div>
<div>
  <button
    mat-raised-button
    color="warn"
    [disabled]="!isNew || !formControl.value"
    (click)="clearSignature()"
  >
    Clear signature
  </button>
</div>
