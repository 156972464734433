<!-- TOP BACKGROUND -->
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->
<!-- CENTER -->
<div class="center">
  <!-- HEADER -->
  <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center">
    <!-- APP TITLE -->
    <div class="logo" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *ngIf="icon">
        {{icon}}
      </mat-icon>
      <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        {{title}}
      </span>
    </div>
    <!-- / APP TITLE -->
  </div>
  <!-- / HEADER -->
  <!-- CONTENT CARD -->
  <div class="content-card">
    <ng-content></ng-content>
  </div>
  <!-- / CONTENT CARD -->
</div>
<!-- / CENTER -->
