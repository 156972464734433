import { Component, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { Image } from '@global/models/image';
import { SocketService } from '@admin/core/services/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@admin/core/services/form.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EditorjsComponent } from '../editorjs/editorjs.component';

@Component({
  selector: 'app-base-edit',
  template: '',
})
export class BaseEditComponent implements AfterViewInit, OnDestroy {
  @ViewChild('editorJs') editorJs: EditorjsComponent;
  editForm: FormGroup;
  editFormArray: FormArray;
  isNew = false;
  controls: ICustomControl[];
  item: any;
  endpoint: string;
  id = '_id';
  returnUrl: string[];
  params: string;
  constructor(
    protected _api: SocketService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _formService: FormService,
    protected _fb: FormBuilder,
    protected _fuseProgressBarService: FuseProgressBarService
  ) {
    this.editForm = this._fb.group({});
  }

  ngAfterViewInit() {
    this._route.params
      .pipe(
        switchMap((params: any) => {
          this.params = params;
          if (params['id']) {
            return this._api.findOne(this.endpoint, params['id']);
          } else {
            this.isNew = true;
            return of({});
          }
        }),
        takeUntil(componentDestroyed(this))
      )
      .subscribe((resp: any) => {
        this.item = resp;
        this.createForm();
      });
  }

  createForm() { }

  saveItem() {
    this._fuseProgressBarService.setMode('query');
    this._fuseProgressBarService.show();
    let data = this.editForm.value;

    if (this.editFormArray) {
      const arrayValues = Object.assign({}, ...this.editFormArray.value);
      data = { ...data, ...arrayValues };
    }

    if (this.editorJs) {
      from(this.editorJs.editor.save())
        .pipe(
          takeUntil(componentDestroyed(this)),
          switchMap((editor) => {
            data.editor = editor;
            const save = this.isNew
              ? this._api.create(this.endpoint, data)
              : this._api.update(this.endpoint, this.item[this.id], data);
            return save;
          })
        )
        .subscribe((resp: any) => {
          this._fuseProgressBarService.hide();
          if (resp) {
            this._router.navigate(this.returnUrl ? this.returnUrl : ['/']);
          }
        });
    } else {
      const save = this.isNew
        ? this._api.create(this.endpoint, data)
        : this._api.update(this.endpoint, this.item[this.id], data);
      save.pipe(takeUntil(componentDestroyed(this))).subscribe((resp: any) => {
        this._fuseProgressBarService.hide();
        if (resp) {
          this._router.navigate(this.returnUrl ? this.returnUrl : ['/']);
        }
      });
    }
  }

  setImages(images: Image[], field: string) {
    this.editForm.get(field).setValue(images);
  }

  addImage(image: Image, field: string) {
    this.editForm.get(field).setValue(image);
  }

  removeImage(field: string) {
    this.editForm.get(field).setValue(null);
  }

  ngOnDestroy() { }
}
