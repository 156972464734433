import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objText',
})
export class ObjTextPipe implements PipeTransform {
  replace(map: any, msg: string) {
    // replace all keys with their values
    Object.keys(map).forEach((key) => {
      msg = msg.replace(new RegExp(`{${key}}`, 'g'), map[key]);
    });
    return msg;
  }

  transform(object: any, selector: string, template?: boolean): any {
    if (template) {
      return this.replace(object, selector);
    } else {
      return object[selector];
    }
  }
}
