<div class="filter my-18" fxLayout="row wrap" fxLayoutGap="0" fxLayoutGap.gt-xs="1%" fxLayoutGap.gt-md="1%">
  <mat-form-field fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-md="24" appearance="outline" *ngIf="showSearch">
    <mat-label>Search by name</mat-label>
    <input matInput placeholder="Search by name" [formControl]="searchCtrl" />
  </mat-form-field>
  <mat-form-field fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-md="24" appearance="outline">
    <mat-label>Location</mat-label>
    <input matInput placeholder="Location" [formControl]="locationCtrl" [matAutocomplete]="autoLocation" />
    <mat-autocomplete #autoLocation="matAutocomplete" (optionSelected)="locationSelected($event)"
      [displayWith]="displayLocation">
      <mat-option *ngFor="let location of locations$ | async" [value]="location">
        {{ location.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-md="24" appearance="outline">
    <mat-label>Lesson</mat-label>
    <input matInput placeholder="Lesson" [formControl]="lessonCtrl" [matAutocomplete]="autoLesson" />
    <mat-autocomplete #autoLesson="matAutocomplete" (optionSelected)="lessonSelected($event)"
      [displayWith]="displayLesson">
      <mat-option *ngFor="let lesson of lessons$ | async" [value]="lesson">
        {{ lesson.title }} {{ lesson.type }} - {{ lesson.age }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-md="24" appearance="outline">
    <mat-label>Timeslot</mat-label>
    <input matInput placeholder="Timeslot" [formControl]="timeslotCtrl" [matAutocomplete]="autoTimeslot" />
    <mat-autocomplete #autoTimeslot="matAutocomplete" (optionSelected)="timeslotSelected($event)"
      [displayWith]="displayTimeslot">
      <mat-option *ngFor="let timeslot of timeslots$ | async" [value]="timeslot">
        {{ timeslot.day_of_week }} {{ timeslot.start_time }} -
        {{ timeslot.end_time }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>