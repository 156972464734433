import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { AuthService } from '@admin/core/services/auth.service';
import { Image } from '@global/models/image';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SocketService } from '@admin/core/services/socket.service';

@Component({
  selector: 'app-single-image-upload',
  templateUrl: './single-image-upload.component.html',
  styleUrls: ['./single-image-upload.component.scss'],
})
export class SingleImageUploadComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() image: Image;
  @Input() config: DropzoneConfigInterface;
  @Input() title: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAdded = new EventEmitter<Image>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemoved = new EventEmitter<Image>();
  dropzoneConfig: DropzoneConfigInterface;
  default_title = 'Featured Image';
  message =
    '<i class="material-icons s-48">cloud_upload</i><h5>Click or drag image here to upload</h5>';
  altText = new FormControl();
  constructor(authService: AuthService, private _api: SocketService) {
    this.dropzoneConfig = {
      headers: {
        Authorization: authService.getToken(),
      },
      uploadMultiple: false,
      maxFiles: 1,
    };
  }

  ngOnInit() {
    this.dropzoneConfig = Object.assign(this.dropzoneConfig, this.config);
    if (this.title) {
      this.default_title = this.title;
    }
  }

  ngAfterViewInit() {
    if (this.image) {
      this.altText.setValue(this.image.alt);
    }
  }

  updateImage() {
    this._api
      .update('image', this.image._id, { alt: this.altText.value })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe();
  }

  removeImage() {
    this.image = null;
    this.onRemoved.emit(this.image);
  }

  onUploadSuccess(resp: any) {
    this.image = resp[1];
    this.onAdded.emit(this.image);
  }

  ngOnDestroy() { }
}
