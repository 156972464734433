<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="to.placeholder"
  [errorStateMatcher]="errorStateMatcher"
/>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let value of filter | async"
    [value]="value"
    (onSelectionChange)="selectionChange(value)"
  >
    {{ value | objText: to.optionLabel:to.optionTemplate }}
  </mat-option>
</mat-autocomplete>
