import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMaskComponent } from './input-mask/input-mask.component';
import { PanelComponent } from './panel/panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TextMaskModule } from 'angular2-text-mask';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { HtmlTemplateComponent } from './html-template/html-template.component';
import { CustomMaterialModule } from '@admin/custom-material/custom-material.module';
import { RepeatComponent } from './repeat/repeat.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { QuillComponent } from './quill/quill.component';
import { ImageComponent } from './image/image.component';
import { QuillModule } from 'ngx-quill';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { SharedModule } from '@admin/shared/shared.module';
import { ChipListComponent } from './chip-list/chip-list.component';

const COMPONENTS = [
  AutocompleteComponent,
  InputMaskComponent,
  PanelComponent,
  HtmlTemplateComponent,
  RepeatComponent,
  SignaturePadComponent,
  TimepickerComponent,
  QuillComponent,
  ImageComponent,
  ChipListComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    RouterModule,
    SignaturePadModule,
    TextMaskModule,
    QuillModule,
    DropzoneModule,
    SharedModule,
    FormlyModule.forChild({
      extras: { checkExpressionOn: 'modelChange' },
      wrappers: [{ name: 'panel', component: PanelComponent }],
      types: [
        { name: 'html-template', component: HtmlTemplateComponent },
        {
          name: 'input-mask',
          component: InputMaskComponent,
          wrappers: ['form-field'],
        },
        { name: 'repeat', component: RepeatComponent },
        {
          name: 'autocomplete',
          component: AutocompleteComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'timepicker',
          component: TimepickerComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'signature-pad',
          component: SignaturePadComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'quill',
          component: QuillComponent,
        },
        {
          name: 'image',
          component: ImageComponent,
        },
        {
          name: 'chip-list',
          component: ChipListComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            defaultValue: [],
          },
        },
      ],
    }),
    FormlySelectModule,
  ],
  exports: [...COMPONENTS],
})
export class FormlyCustomModule {}
