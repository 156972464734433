import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';

import { FieldType } from '@ngx-formly/material';

import { Observable } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';

import { Location } from '@global/models/location';
import { ObjTextPipe } from '@admin/shared/pipes/obj-text.pipe';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent extends FieldType
  implements OnInit, AfterViewInit {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;
  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocomplete: MatAutocompleteTrigger;

  filter: Observable<any>;

  constructor(private objText: ObjTextPipe) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.filter = this.formControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((value: string) => this.to.filter(value))
    );
  }

  get displayFn(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => {
      return item
        ? this.objText.transform(
            item,
            this.to.optionLabel,
            this.to.optionTemplate
          )
        : undefined;
    };
  }

  selectionChange($event: any) {
    return this.to.selectionChange
      ? this.to.selectionChange(this.formControl, $event)
      : undefined;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.autocomplete as any)._formField = this.formField;
  }
}
