import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AuthService } from '@admin/core/services/auth.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Image } from '@global/models/image';
import { environment } from '@env/environment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends FieldType implements OnInit {
  image: Image;
  dropzoneConfig: DropzoneConfigInterface;
  displayLink: string;
  default_title = 'Featured Image';
  message =
    '<i class="material-icons s-48">cloud_upload</i><h5>Click or drag image here to upload</h5>';
  environment = environment;

  constructor(authService: AuthService) {
    super();
    this.dropzoneConfig = {
      headers: {
        Authorization: authService.getToken(),
      },
      uploadMultiple: false,
      maxFiles: 1,
      acceptedFiles: 'image/*',
    };
  }

  ngOnInit() {
    this.image = this.formControl.value;
    this.displayLink = this.formatDisplayLink();
    this.dropzoneConfig = Object.assign(this.dropzoneConfig, this.to.config);
    if (this.to.title) {
      this.default_title = this.to.title;
    }
  }

  removeImage() {
    this.image = null;
    this.formControl.setValue(null);
  }

  onUploadSuccess(resp: any) {
    this.image = resp[1];
    this.formControl.setValue(this.image);
  }

  formatDisplayLink() {
    const formControlNameValue = this.formControl.parent.get('name').value;
    return formControlNameValue;
  }
}
