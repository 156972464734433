<ng-container [formGroup]="form">
  <mat-form-field [appearance]="control.appearance" [floatLabel]="control.floatLabel" class="w-100-p">
    <mat-label>{{control.label}}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let value of control.value" [selectable]="true" [removable]="true" (removed)="remove(value)">
        {{value | objText:control.optionLabel:control.optionTemplate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input [placeholder]="control.placeholder" #chipInput [formControl]="chipCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of results$ | async" [value]="option">
        {{option | objText:control.optionLabel:control.optionTemplate}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
