import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CookieService } from '@global/services/cookie.service';
import * as intersection from 'lodash.intersection';
import { throwError } from 'rxjs';

import { FeathersService } from '@admin/core/services/feathers.service';
import { ToastrService } from 'ngx-toastr';
import { navigation } from '@admin/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '/';
  host: string = environment.host;
  navigation: any;
  constructor(
    private feathers: FeathersService,
    private router: Router,
    private toast: ToastrService,
    private cookieService: CookieService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private _fuseNavigationService: FuseNavigationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  getToken() {
    return this.cookieService.getToken(environment.cookie_key);
  }

  setToken(token: string) {
    this.cookieService.setToken(environment.cookie_key, token);
    this.getToken();
  }

  getUser() {
    if (this.getToken()) {
      const token = this.getToken();
      const decoded = this.cookieService.decodeToken(token);

      if (decoded && decoded.userid) {
        this.angulartics2GoogleTagManager.setUsername(decoded.userid);
      }
      return decoded && decoded.userid ? decoded : null;
    }
  }

  redirectNonAdmin() {
    if (this.getToken() && !this.checkRoles(['Admin', 'Coach', 'Franchise'])) {
      this.document.location.href = environment.site;
    }
  }

  checkRoles(roles: any) {
    const user = this.getUser();
    return intersection(roles, user.roles).length > 0;
  }

  login(email: string, password: string) {
    this.feathers
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then((resp) => {
        if (resp.accessToken) {
          this.setToken(resp.accessToken);
        }
        this.redirectNonAdmin();
        this.router.navigate([this.redirectUrl]);
        this._fuseNavigationService.unregister('main');
        this.navigation = navigation.filter((n) => {
          if (this.loggedIn() && !n.roles) {
            return n;
          }
          if (this.loggedIn() && n.roles && this.checkRoles(n.roles)) {
            return n;
          }
        });
        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
      })
      .catch(this.handleError.bind(this));
  }

  googleLogin() {
    this.feathers
      .authenticate({
        strategy: 'google',
      })
      .then(() => {
        this.router.navigate([this.redirectUrl]);
      })
      .catch(this.handleError.bind(this));
  }

  logout() {
    this.cookieService.removeToken(environment.cookie_key);
    this.feathers.logout();
    this.router.navigateByUrl('/auth/login');
  }

  loggedIn() {
    if (!this.getToken() || (this.getToken() && !this.getUser())) {
      return false;
    }
    return this.cookieService.tokenNotExpired(this.getToken());
  }

  private handleError(error: any) {
    console.log(error);
    if (error && error.code === 404) {
      this.router.navigate(['/404']);
    }
    if ((error && error.code === 403) || (error && error.code === 405)) {
      this.router.navigate(['/404']);
    }

    this.toast.error(error.message, 'Error');
    return throwError(error);
  }
}
