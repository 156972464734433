{{ address.address_line_1 }}
<ng-container *ngIf="address.address_line_2"
  ><br />{{ address.address_line_2 }}</ng-container
>
<br />{{ address.city }}
<ng-container *ngIf="address.region"><br />{{ address.region }}</ng-container>
<br />{{ address.postcode }}<br />{{ address.country }}
<ng-container *ngIf="address.phone"><br />{{ address.phone }}</ng-container>
<ng-container *ngIf="email && address.email"
  ><br />{{ address.email }}</ng-container
>
