<div class="profile-box info-box general">
  <header class="accent">
    <div class="title">General Information</div>
  </header>
  <div class="content">
    <div class="info-line">
      <div class="title">Pedal Points</div>
      <div class="info">
        {{ student.points ? student.points : 0 }}
      </div>
    </div>
    <div class="info-line">
      <div class="title">Date of Birth</div>
      <div class="info">{{ student.date_of_birth }}</div>
    </div>
    <div class="info-line">
      <div class="title">Age</div>
      <div class="info">{{ student.age }}</div>
    </div>
    <div class="info-line">
      <div class="title">Photo Consent</div>
      <div class="info">
        {{ student.photo_consent ? 'Yes' : 'No' }}
      </div>
    </div>
    <div class="info-line">
      <div class="title">Tshirt Size</div>
      <div class="info">{{ student.tshirt_size }}</div>
    </div>
    <div class="info-line" *ngIf="student.bc_membership_number">
      <div class="title">
        British Cycling Membership Number
      </div>
      <div class="info">{{ student.bc_membership_number }}</div>
    </div>
    <div class="info-line" *ngIf="student.bc_cycling_club">
      <div class="title">British Cycling Club</div>
      <div class="info">{{ student.bc_cycling_club }}</div>
    </div>

    <div class="info-line" *ngIf="student.address">
      <div class="title">Address</div>
      <div class="info">
        <app-address [address]="student.address"></app-address>
      </div>
    </div>
  </div>
</div>
