import { Component, Inject, OnDestroy, OnInit, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, filter } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from '@admin/navigation/navigation';
import { locale as mainEnglish } from '@admin/core/i18n/en';
import { locale as navigationEnglish } from '@admin/navigation/i18n/en';
import { AuthService } from './core/services/auth.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppUpdateComponent } from './core/components/app-update/app-update.component';
import { RoutingStateService } from './core/services/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _auth: AuthService,
    private swUpdate: SwUpdate,
    private readonly zone: NgZone,
    private _bottomSheet: MatBottomSheet,
    routingState: RoutingStateService
  ) {
    routingState.loadRouting();
    // Get default navigation

    const filterNav = (nav) => {
      return nav.filter((n) => {
        if (n.children) {
          n.children = filterNav(n.children);
        }
        if (!n.roles || (n.roles && this._auth.checkRoles(n.roles))) {
          return n;
        }
      });
    };
    if (this._auth.loggedIn()) {
      this.navigation = filterNav(navigation);
    }

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      mainEnglish,
      navigationEnglish
    );

    // Use a language
    this._translateService.use('en');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    if (this.swUpdate && this.swUpdate.isEnabled) {
      const updateInterval = 1000 * 60 * 60; // 6 Hours
      // See issue: https://github.com/angular/angular/issues/20970
      this.zone.runOutsideAngular(() =>
        interval(updateInterval)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe(() => this.zone.run(() => this.swUpdate.checkForUpdate()))
      );
    }
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        for (const className of this.document.body.classList) {
          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    // If SW update available show update button
    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.swUpdate.available
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(() => this.showAppUpdate());
    }
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  showAppUpdate() {
    this._bottomSheet.open(AppUpdateComponent);
  }

  ngOnDestroy(): void { }
}
