import {
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY } from 'rxjs';
import { Lesson } from '@global/models/lesson';
import { Timeslot } from '@global/models/timeslot';
import { SocketService } from '@admin/core/services/socket.service';
import {
  switchMap,
  map,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Location } from '@global/models/location';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-timeslot-filter',
  templateUrl: './timeslot-filter.component.html',
  styleUrls: ['./timeslot-filter.component.scss'],
  exportAs: 'timeslotFilter',
})
export class TimeslotFilterComponent implements OnDestroy, OnInit {
  @Input() showSearch = true;
  @Output() queryUpdated = new EventEmitter<any>();
  locationCtrl = new FormControl();
  locations$: Observable<Location[]>;
  locationId: string;
  lessonCtrl = new FormControl({ value: '', disabled: true });
  lessons$: Observable<Lesson[]>;
  lessonId: string;
  timeslotCtrl = new FormControl({ value: '', disabled: true });
  timeslots$: Observable<Timeslot[]>;
  timeslotId: string;
  searchCtrl = new FormControl();
  query: any;
  constructor(
    private _api: SocketService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.locations$ = this.locationCtrl.valueChanges.pipe(
      switchMap((value) => {
        this.lessonId = null;
        this.timeslotId = null;
        this.lessonCtrl.setValue(null);
        this.lessonCtrl.disable();
        this.timeslotCtrl.setValue(null);
        this.timeslotCtrl.disable();
        this.setQuery();
        if (value) {
          return this._api.find('location', { $search: value }, false);
        } else {
          this.locationId = null;
          this.setQuery();
          return EMPTY;
        }
      }),
      map((resp: any) => resp.data)
    );
    this.lessons$ = this.lessonCtrl.valueChanges.pipe(
      switchMap((value) => {
        this.timeslotId = null;
        this.timeslotCtrl.setValue(null);
        this.timeslotCtrl.disable();
        this.setQuery();
        if (value && this.locationId) {
          return this._api.find(
            'lesson',
            { $search: value, location: this.locationId },
            false
          );
        } else {
          this.lessonId = null;
          this.setQuery();
          return EMPTY;
        }
      }),
      map((resp: any) => resp.data)
    );
    this.timeslots$ = this.timeslotCtrl.valueChanges.pipe(
      switchMap((value) => {
        if (value && this.lessonId) {
          return this._api.find(
            'lesson/timeslot',
            { $search: value, lesson: this.lessonId },
            false
          );
        } else {
          this.timeslotId = null;
          this.setQuery();
          return EMPTY;
        }
      }),
      map((resp: any) => resp.data)
    );
    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(() => {
        this.setQuery();
      });
  }

  ngOnInit() {
    const params = this._route.snapshot.queryParams;
    if (params.location) {
      this._api
        .findOne('location', params.location, false)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((resp) => {
          this.locationCtrl.setValue(resp, { emitEvent: false });
          this.locationId = resp._id;
          this.lessonCtrl.enable();
          this.setQuery();
        });
    }
    if (params.lesson) {
      this._api
        .findOne('lesson', params.lesson, false)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((resp) => {
          this.lessonCtrl.setValue(resp, { emitEvent: false });
          this.lessonId = resp._id;
          this.timeslotCtrl.enable();
          this.setQuery();
        });
    }
    if (params.timeslot) {
      this._api
        .findOne('lesson/timeslot', params.timeslot, false)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((resp) => {
          this.timeslotCtrl.setValue(resp, { emitEvent: false });
          this.timeslotId = resp._id;
          this.setQuery();
        });
    }
  }

  setQuery() {
    let query: any = null;
    if (
      this.locationId ||
      this.lessonId ||
      this.timeslotId ||
      this.searchCtrl.value
    ) {
      query = {};
    }
    if (this.locationId) {
      query.location = this.locationId;
    }

    if (this.lessonId) {
      query.lesson = this.lessonId;
    }

    if (this.timeslotId) {
      query.timeslot = this.timeslotId;
    }

    if (this.searchCtrl.value) {
      query.$search = this.searchCtrl.value;
    }

    this.query = query;
    this.queryUpdated.emit(this.query);
    this._router.navigate([], {
      queryParams: this.query,
      relativeTo: this._route,
    });
  }

  get displayLocation(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => item ? item.name : undefined;
  }

  get displayLesson(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => item ? `${item.title} ${item.type} - ${item.age} ` : undefined;
  }

  get displayTimeslot(): (item?: string) => string | undefined {
    return (item?: any): string | undefined => item
      ? `${item.day_of_week} ${item.start_time}-${item.end_time} `
      : undefined;
  }

  locationSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value._id) {
      this.locationId = event.option.value._id;
      this.lessonCtrl.enable();
      this.setQuery();
    }
  }

  lessonSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value._id) {
      this.lessonId = event.option.value._id;
      this.timeslotCtrl.enable();
      this.setQuery();
    }
  }

  timeslotSelected(event: MatAutocompleteSelectedEvent): void {
    this.timeslotId = event.option.value._id;
    this.setQuery();
  }

  ngOnDestroy() { }
}
