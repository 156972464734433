<h4 *ngIf="title">{{ title }}</h4>
<dropzone [config]="dropzoneConfig" [message]="message" (success)="onUploadSuccess($event)" [useDropzoneClass]="false">
</dropzone>
<div class="mt-16" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" cdkDropList
  cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" [cdkDropListData]="images">
  <div fxFlex="20" *ngFor="let image of images" cdkDrag [cdkDragData]="image">
    <mat-card>
      <img mat-card-image [appLazysizes]="image.public_id" *ngIf="image && image.type === 'image'" />
      <h6 *ngIf="image && image.type === 'file'">{{ image.name }}</h6>
      <mat-card-actions [align]="'end'">
        <button mat-raised-button (click)="removeImage(image)">Remove</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>