import { JwtService } from '@global/services/jwt.service';
import { Injectable } from '@angular/core';
import { CookieStorage } from 'cookie-storage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  cookieStorage = new CookieStorage({
    path: '/',
    domain: environment.cookie,
    expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
  });
  constructor(protected jwt: JwtService) {}
  getToken(key: string) {
    return this.cookieStorage.getItem(key);
  }
  setToken(key: string, value: string) {
    this.cookieStorage.setItem(key, value);
  }
  decodeToken(token: string) {
    return this.jwt.decodeToken(token);
  }
  tokenNotExpired(token: string) {
    return !this.jwt.isTokenExpired(token);
  }
  removeToken(key: string) {
    this.cookieStorage.removeItem(key);
  }
}
