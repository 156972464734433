import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { FeathersService } from './feathers.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from './loading.service';
import { environment } from '@env/environment';
import { AuthService } from '@admin/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  host: string = environment.host;
  endpoint: string;
  loading_enabled = true;
  constructor(
    private feathers: FeathersService,
    private loading: LoadingService,
    private router: Router,
    private toast: ToastrService,
    private auth: AuthService
  ) { }

  getUrl(endpoint: string) {
    return `${this.host}/${endpoint}`;
  }

  service(endpoint: string) {
    return this.feathers.service(endpoint);
  }

  find(endpoint: string, query?: any, loader: boolean = true) {
    if (loader) {
      this.showLoader();
    }
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };
    return this.service(endpoint)
      .watch()
      .find({ query })
      .pipe(
        tap(() => {
          this.onEnd();
        }),
        catchError(this.handleError.bind(this))
      );
  }

  findOne(
    endpoint: string,
    id: number | string,
    query?: any,
    loader: boolean = true
  ) {
    if (loader) {
      this.showLoader();
    }
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };
    return this.service(endpoint)
      .watch()
      .get(id, { query })
      .pipe(
        tap(() => {
          this.onEnd();
        }),
        catchError(this.handleError.bind(this))
      );
  }

  create(endpoint: string, data: any, query?: any) {
    this.showLoader();
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };

    return this.service(endpoint)
      .watch()
      .create(data, { query })
      .pipe(
        tap(() => {
          this.onEnd();
        }),
        catchError(this.handleError.bind(this))
      );
  }

  update(endpoint: string, id: number | string, data: any, query?: any) {
    this.showLoader();
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };
    return this.service(endpoint)
      .watch()
      .patch(id, data, { query })
      .pipe(
        tap(() => {
          this.onEnd();
        }),
        catchError(this.handleError.bind(this))
      );
  }

  remove(endpoint: string, id: number | string, query?: any) {
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };
    this.service(endpoint).remove(id, { query });
  }

  delete(endpoint: string, id: number | string, query?: any) {
    query = {
      ...query,
      $client: { schema: 'po-admin' }
    };
    return this.service(endpoint)
      .watch()
      .remove(id, { query })
      .pipe(
        tap(() => {
          this.onEnd();
        }),
        catchError(this.handleError.bind(this))
      );
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    if (this.loading_enabled) {
      this.loading.setLoading(true);
    }
  }

  private hideLoader(): void {
    this.loading.setLoading(false);
  }

  private handleError(error: any) {
    console.log('error', error);

    this.hideLoader();
    if (error && error.code === 401 && error.message === 'invalid signature') {
      this.auth.logout();
    }

    if (error && error.code === 404) {
      this.router.navigate(['/error/notfound']);
    }
    if ((error && error.code === 403) || (error && error.code === 405)) {
      this.router.navigate(['/error/forbidden']);
    }

    this.toast.error(error.message, 'Error');
    return throwError(error);
  }
}
