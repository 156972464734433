import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SocketService } from '@admin/core/services/socket.service';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-location-form-modal',
  templateUrl: './location-form-modal.component.html',
  styleUrls: ['./location-form-modal.component.scss']
})
export class LocationFormModalComponent implements OnInit, OnDestroy {
  locationForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<LocationFormModalComponent>,
    private _fb: FormBuilder,
    private _api: SocketService
  ) {
    this.locationForm = this._fb.group({});
  }

  ngOnInit() {}

  addLocation() {
    this._api
      .create('location', this.locationForm.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(resp => {
        this.dialogRef.close(resp);
      });
  }

  ngOnDestroy() {}
}
