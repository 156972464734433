<div
  *ngFor="let field of field.fieldGroup; let i = index"
  style="margin: 30px 0;"
>
  <formly-field class="col" [field]="field"></formly-field>
  <button mat-raised-button color="warn" (click)="remove(i)">
    Remove
  </button>
  <mat-divider style="margin-top: 30px;"></mat-divider>
</div>
<div style="margin: 30px 0;">
  <button mat-raised-button color="accent" (click)="add()">
    {{ to.addText }}
  </button>
</div>
