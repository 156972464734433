<mat-card *ngIf="image; else uploadBlock">
  <mat-card-header>
    <mat-card-title>{{ default_title }}</mat-card-title>
  </mat-card-header>
  <img mat-card-image [appLazysizes]="image.public_id" *ngIf="image && image.type === 'image'" />
  <mat-card-content>
    <ng-container *ngIf="image && image.type === 'file'">
      <h6 *ngIf="image.name">
        {{ image.name }}
      </h6>
      <a *ngIf="image.public_id" [href]="environment.image_cdn + '/' + image.public_id">
        View <b>{{ displayLink ? displayLink : '' }}</b>
        {{ default_title ? default_title : '' }}
      </a>
    </ng-container>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button (click)="removeImage()" mat-raised-button>Remove</button>
  </mat-card-actions>
</mat-card>
<ng-template #uploadBlock>
  <h3 class="font-weight-900" *ngIf="to.label">{{ to.label }}</h3>
  <dropzone (success)="onUploadSuccess($event)" [config]="dropzoneConfig" [message]="message"
    [useDropzoneClass]="false"></dropzone>
</ng-template>