import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotAuthGuard } from '@admin/core/guards/not-auth.guard';
import { AuthGuard } from '@admin/core/guards/auth.guard';
import { RoleGuard } from '@admin/core/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [NotAuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'orders',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderModule),
    data: {
      roles: ['Admin', 'Franchise'],
    },
  },
  {
    path: 'discounts',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./discount/discount.module').then((m) => m.DiscountModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'users',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'members',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./membership/membership.module').then((m) => m.MembershipModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'categories',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./category/category.module').then((m) => m.CategoryModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'resource',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./resource/resource.module').then((m) => m.ResourceModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'pages',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import('./page/page.module').then((m) => m.PageModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'lessons',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/lesson/lesson.module').then((m) => m.LessonModule),
    data: {
      roles: ['Admin', 'Franchise'],
    },
  },
  {
    path: 'rewards',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/rewards/rewards.module').then((m) => m.RewardsModule),
    data: {
      roles: ['Admin', 'Franchise'],
    },
  },
  {
    path: 'outcomes',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/outcome/outcome.module').then((m) => m.OutcomeModule),
    data: {
      roles: ['Admin', 'Franchise'],
    },
  },
  {
    path: 'waiting-list',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/waiting-list/waiting-list.module').then(
        (m) => m.WaitingListModule
      ),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'products',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./product/product.module').then((m) => m.ProductModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'locations',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/location/location.module').then(
        (m) => m.LocationModule
      ),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'registers',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./coaching/register/register.module').then(
        (m) => m.RegisterModule
      ),
    data: {
      roles: ['Admin', 'Coach', 'Franchise'],
    },
  },
  {
    path: 'students',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./coaching/student/student.module').then((m) => m.StudentModule),
    data: {
      roles: ['Admin', 'Coach', 'Franchise'],
    },
  },
  {
    path: 'coaching/rewards',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./coaching/reward/reward.module').then((m) => m.RewardModule),
    data: {
      roles: ['Admin', 'Coach', 'Franchise'],
    },
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./setting/setting.module').then((m) => m.SettingModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'reports',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'blog',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'franchise',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./franchise/franchise.module').then((m) => m.FranchiseModule),
  },
  {
    path: 'session',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/session/session.module').then((m) => m.SessionModule),
  },
  {
    path: 'risk-assessments',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./risk-assessment/risk-assessment.module').then(
        (m) => m.RiskAssessmentModule
      ),
    data: {
      roles: ['Admin', 'Coach'],
    },
  },
  {
    path: 'lesson-plan-activities',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import(
        './coaching/lesson-plan-activities/lesson-plan-activities.module'
      ).then((m) => m.LessonPlanActivitiesModule),
    data: {
      roles: ['Admin', 'Coach'],
    },
  },
  {
    path: 'one-to-one',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./academy/one-to-one/one-to-one.module').then((m) => m.OneToOneModule),
    data: {
      roles: ['Admin', 'Coach', 'Franchise'],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
