import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { VERSION } from '@env/version';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn:
    'https://b444bf3ae93e4cc1bfafe9c76daebb3b@o220243.ingest.sentry.io/1538313',
  environment: environment.production ? 'production' : 'development',
  release: VERSION.version,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.site, environment.host],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.2,
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap()
    .then(() => {
      if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    })
    .catch((err) => console.log(err));
}
