import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { BaseListComponent } from './components/base-list/base-list.component';
import { BaseEditComponent } from './components/base-edit/base-edit.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { PublishingFormComponent } from './components/publishing-form/publishing-form.component';
import { SeoFormComponent } from './components/seo-form/seo-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormControlComponent } from './components/form-group/form-control.component';
import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressFormModalComponent } from './components/address-form/address-form-modal.component';
import { LocationFormModalComponent } from './components/location-form/location-form-modal.component';
import { LocationFormComponent } from './components/location-form/location-form.component';
import { QuillModule } from 'ngx-quill';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { CustomMaterialModule } from '@admin/custom-material/custom-material.module';
import { SingleImageUploadComponent } from './components/single-image-upload/single-image-upload.component';
import { FormChiplistComponent } from './components/form-chiplist/form-chiplist.component';
import { MultiImageUploadComponent } from './components/multi-image-upload/multi-image-upload.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObjTextPipe } from './pipes/obj-text.pipe';
import { LinkyModule } from 'ngx-linky';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomFlexDirective } from './directives/custom-flex.directive';
import { CustomLayoutDirective } from './directives/custom-layout.directive';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { TextAreaPipe } from '@admin/shared/pipes/text-area.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DiffTextComponent } from './components/diff-text/diff-text.component';
import { TimeslotFilterComponent } from './components/timeslot-filter/timeslot-filter.component';
import { EditorjsComponent } from './components/editorjs/editorjs.component';
import { FormlyModule } from '@ngx-formly/core';
import { AddressComponent } from './components/address/address.component';
import { StudentGeneralComponent } from './components/student/student-general/student-general.component';
import { StudentMedicalComponent } from './components/student/student-medical/student-medical.component';
import { LazysizesDirective } from './directives/lazysizes.directive';

const COMPONENTS = [
  BaseListComponent,
  BaseEditComponent,
  ListViewComponent,
  FormGroupComponent,
  FormControlComponent,
  FormAutocompleteComponent,
  PublishingFormComponent,
  SeoFormComponent,
  AddressFormComponent,
  AddressFormModalComponent,
  LocationFormModalComponent,
  LocationFormComponent,
  SingleImageUploadComponent,
  MultiImageUploadComponent,
  FormChiplistComponent,
  DiffTextComponent,
  TimeslotFilterComponent,
  EditorjsComponent,
  AddressComponent,
  StudentGeneralComponent,
  StudentMedicalComponent,
];

const DIRECTIVES = [
  CustomFlexDirective,
  CustomLayoutDirective,
  LazysizesDirective,
];

const PIPES = [ObjTextPipe, DateSuffixPipe, TextAreaPipe];

@NgModule({
    declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
    exports: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        LinkyModule,
        TextMaskModule,
        ClipboardModule,
        FormlyModule,
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        CustomMaterialModule,
        ReactiveFormsModule,
        QuillModule,
        DropzoneModule,
        DragDropModule,
        LinkyModule,
        TextMaskModule,
        FormlyModule,
    ],
    providers: [TextAreaPipe, ObjTextPipe]
})
export class SharedModule {}
